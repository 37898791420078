import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    filterSwitch: false,
}

const applyFilterQuotation = (state, action) => {

    return updateObject(state, { filterSwitch: action?.filterSwitchTab
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // leadtchh
        case actionTypes.UPDATE_FILTER_TAB_POSSITION:
            return applyFilterQuotation(state, action);
        default:
            return state;
    }
};

export default reducer;