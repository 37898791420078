import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    userInfo: "",
}



const loginSuccessUserInfo = (state, action) => {
    return updateObject(state, { userInfo: action?.login_agent_data_info });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // leadtchh
           
            case actionTypes.LOGIN_SUCCESS_USERINFO:
            return loginSuccessUserInfo(state, action);
        default:
            return state;
    }
};

export default reducer;