// Lead Master Azim
export const FETCH_ALL_LEADS_START = "FETCH_ALL_LEADS_START";
export const FETCH_ALL_LEADS_SUCCESS = "FETCH_ALL_LEADS_SUCCESS";
export const FETCH_ALL_LEADS_FAIL = "FETCH_ALL_LEADS_FAIL";

export const CURRENT_UPDATING_TAB = "CURRENT_UPDATING_TAB";

export const FETCH_TEAM_MEMBER_START = "FETCH_TEAM_MEMBER_START";
export const FETCH_TEAM_MEMBER_SUCCESS = "FETCH_TEAM_MEMBER_SUCCESS";
export const FETCH_TEAM_MEMBER_FAIL = "FETCH_TEAM_MEMBER_FAIL";

export const FETCH_DESIGNATION_START = "FETCH_DESIGNATION_START";
export const FETCH_DESIGNATION_SUCCESS = "FETCH_DESIGNATION_SUCCESS";
export const FETCH_DESIGNATION_FAIL = "FETCH_DESIGNATION_FAIL";

export const UPDATE_TAB_POSSITION = "UPDATE_TAB_POSSITION";
export const UPDATE_ALLCATION_TAB_POSSITION = "UPDATE_ALLCATION_TAB_POSSITION";
export const UPDATE_ALLCATED_CHECKED_LEADS = "UPDATE_ALLCATED_CHECKED_LEADS";
export const CURRENT_UPDATING_ID = "CURRENT_UPDATING_ID";
// documetnid is will come here
export const CURRENT_DOCUMENTS_ID = "CURRENT_DOCUMENTS_ID";

export const FETCH_ALL_TEAM_COUNT = "FETCH_ALL_TEAM_COUNT";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const FETCH_ALL_TEAM_COUNTT = "FETCH_ALL_TEAM_COUNTT";

export const HEADER_NAME = "HEADER_NAME";

// export const FETCH_TODAY_LEADS_START =  'FETCH_TODAY_LEADS_START'
// export const FETCH_TODAY_LEADS_SUCCESS =  'FETCH_TODAY_LEADS_SUCCESS'
// export const FETCH_TODAY_LEADS_FAIL =  'FETCH_TODAY_LEADS_FAIL'

// export const FETCH_OPEN_LEADS_START =  'FETCH_OPEN_LEADS_START'
// export const FETCH_OPEN_LEADS_SUCCESS =  'FETCH_OPEN_LEADS_SUCCESS'
// export const FETCH_OPEN_LEADS_FAIL =  'FETCH_OPEN_LEADS_FAIL'

// export const FETCH_CONVERTED_LEADS_START =  'FETCH_CONVERTED_LEADS_START'
// export const FETCH_CONVERTED_LEADS_SUCCESS =  'FETCH_CONVERTED_LEADS_SUCCESS'
// export const FETCH_CONVERTED_LEADS_FAIL =  'FETCH_CONVERTED_LEADS_FAIL'

// export const FETCH_FAILED_LEADS_START =  'FETCH_FAILED_LEADS_START'
// export const FETCH_FAILED_LEADS_SUCCESS =  'FETCH_FAILED_LEADS_SUCCESS'
// export const FETCH_FAILED_LEADS_FAIL =  'FETCH_FAILED_LEADS_FAIL'

export const FETCH_LEAD_CATEGORY_START = "FETCH_LEAD_CATEGORY_START";
export const FETCH_LEAD_CATEGORY_SUCCESS = "FETCH_LEAD_CATEGORY_SUCCESS";
export const FETCH_LEAD_CATEGORY_FAIL = "FETCH_LEAD_CATEGORY_FAIL";

export const FETCH_LEAD_DETAILS_START = "FETCH_LEAD_DETAILS_START";
export const FETCH_LEAD_DETAILS_SUCCESS = "FETCH_LEAD_DETAILS_SUCCESS";
export const FETCH_LEAD_DETAILS_FAIL = "FETCH_LEAD_DETAILS_FAIL";

export const FETCH_HISTORY_START = "FETCH_HISTORY_START";
export const FETCH_HISTORY_SUCCESS = "FETCH_HISTORY_SUCCESS";
export const FETCH_HISTORY_FAIL = "FETCH_HISTORY_FAIL";

export const CREATE_LEAD_START = "CREATE_LEAD_START";
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS";
export const CREATE_LEAD_FAIL = "CREATE_LEAD_FAIL";

export const EDIT_LEAD_START = "EDIT_LEAD_START";
export const EDIT_LEAD_SUCCESS = "EDIT_LEAD_SUCCESS";
export const EDIT_LEAD_FAIL = "EDIT_LEAD_FAIL";

export const STORE_FORM_SUCCESS = "STORE_FORM_SUCCESS";

export const FETCH_PRODUCT_START = "FETCH_PRODUCT_START";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAIL = "FETCH_PRODUCT_FAIL";

export const FETCH_PLAN_NAME_START = "FETCH_PLAN_NAME_START";
export const FETCH_PLAN_NAME_SUCCESS = "FETCH_PLAN_NAME_SUCCESS";
export const FETCH_PLAN_NAME_FAIL = "FETCH_PLAN_NAME_FAIL";
// Addresses
export const FETCH_STATES_START = "FETCH_STATES_START";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_STATES_FAIL = "FETCH_STATES_FAIL";

export const FETCH_CITIES_START = "FETCH_CITIES_START";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_CITIES_FAIL = "FETCH_CITIES_FAIL";
// Addresses zipCode
export const FETCH_ZIPCODE_START = "FETCH_ZIPCODE_START";
export const FETCH_ZIPCODE_SUCCESS = "FETCH_ZIPCODE_SUCCESS";
export const FETCH_ZIPCODE_FAIL = "FETCH_ZIPCODE_FAIL";
// citizenship
export const FETCH_CITIZENSHIP_START = "FETCH_CITIZENSHIP_START";
export const FETCH_CITIZENSHIP_SUCCESS = "FETCH_CITIZENSHIP_SUCCESS";
export const FETCH_CITIZENSHIP_FAIL = "FETCH_CITIZENSHIP_FAIL";

//Vehicle Category

export const FETCH_VEHICLECATEGORY_START = "FETCH_VEHICLECATEGORY_START";
export const FETCH_VEHICLECATEGORY_SUCCESS = "FETCH_VEHICLECATEGORY_SUCCESS";
export const FETCH_VEHICLECATEGORY_FAIL = "FETCH_VEHICLECATEGORY_FAIL";

// Make
export const FETCH_MAKE_START = "FETCH_MAKE_START";
export const FETCH_MAKE_SUCCESS = "FETCH_MAKE_SUCCESS";
export const FETCH_MAKE_FAIL = "FETCH_MAKE_FAIL";

// Model
// suffix
export const FETCH_SUFFIX_START = "FETCH_SUFFIX_START";
export const FETCH_SUFFIX_SUCCESS = "FETCH_SUFFIX_SUCCESS";
export const FETCH_SUFFIX_FAIL = "FETCH_SUFFIX_FAIL";
// prefix
export const FETCH_PREFIX_START = "FETCH_PREFIX_START";
export const FETCH_PREFIX_SUCCESS = "FETCH_PREFIX_SUCCESS";
export const FETCH_PREFIX_FAIL = "FETCH_PREFIX_FAIL";

export const FETCH_MODAL_START = "FETCH_MODAL_START";
export const FETCH_MODAL_SUCCESS = "FETCH_MODAL_SUCCESS";
export const FETCH_MODAL_FAIL = "FETCH_MODAL_FAIL";

//Vehicle Type

export const FETCH_VEHICLETYPE_START = "FETCH_VEHICLETYPE_START";
export const FETCH_VEHICLETYPE_SUCCESS = "FETCH_VEHICLETYPE_SUCCESS";
export const FETCH_VEHICLETYPE_FAIL = "FETCH_VEHICLETYPE_FAIL";

// Model Year

export const FETCH_MODELYEAR_START = "FETCH_MODELYEAR_START";
export const FETCH_MODELYEAR_SUCCESS = "FETCH_MODELYEAR_SUCCESS";
export const FETCH_MODELYEAR_FAIL = "FETCH_MODELYEAR_FAIL";

// Coverage Selected Details

// Model Year

export const FETCH_COVERAGESELECTEDVALUE_START =
  "FETCH_COVERAGESELECTEDVALUE_START";
export const FETCH_COVERAGESELECTEDVALUE_SUCCESS =
  "FETCH_COVERAGESELECTEDVALUE_SUCCESS";
export const FETCH_COVERAGESELECTEDVALUE_FAIL =
  "FETCH_COVERAGESELECTEDVALUE_FAIL";

// Vehicle value

// export const FETCH_VEHICLEVALUE_START = "FETCH_VEHICLEVALUE_START";
// export const FETCH_VEHICLEVALUE_SUCCESS = "FETCH_VEHICLEVALUE_SUCCESS";
// export const FETCH_VEHICLEVALUE_FAIL = "FETCH_VEHICLEVALUE_FAIL";
// policy Term

export const FETCH_POLICYTERM_START = "FETCH_POLICYTERM_START";
export const FETCH_POLICYTERM_SUCCESS = "FETCH_POLICYTERM_SUCCESS";
export const FETCH_POLICYTERM_FAIL = "FETCH_POLICYTERM_FAIL";
//Sub model

export const FETCH_SUBMODEL_START = "FETCH_SUBMODEL_START";
export const FETCH_SUBMODEL_SUCCESS = "FETCH_SUBMODEL_SUCCESS";
export const FETCH_SUBMODEL_FAIL = "FETCH_SUBMODEL_FAIL";

// Type of use

export const FETCH_TYPEOFUSE_START = "FETCH_TYPEOFUSE_START";
export const FETCH_TYPEOFUSE_SUCCESS = "FETCH_TYPEOFUSE_SUCCESS";
export const FETCH_TYPEOFUSE_FAIL = "FETCH_TYPEOFUSE_FAIL";

// Vehicle value

export const FETCH_VEHICLEVALUE_START = "FETCH_VEHICLEVALUE_START";
export const FETCH_VEHICLEVALUE_SUCCESS = "FETCH_VEHICLEVALUE_SUCCESS";
export const FETCH_VEHICLEVALUE_FAIL = "FETCH_VEHICLEVALUE_FAIL";

//subline

export const FETCH_SUBLINE_START = "FETCH_SUBLINE_START";
export const FETCH_SUBLINE_SUCCESS = "FETCH_SUBLINE_SUCCESS";
export const FETCH_SUBLINE_FAIL = "FETCH_SUBLINE_FAIL";

//Area of Usage

export const FETCH_AREAOFUSAGE_START = "FETCH_AREAOFUSAGE_START";
export const FETCH_AREAOFUSAGE_SUCCESS = "FETCH_AREAOFUSAGE_SUCCESS";
export const FETCH_AREAOFUSAGE_FAIL = "FETCH_AREAOFUSAGE_FAIL";

// Group policy

export const FETCH_GROUPPOLICY_START = "FETCH_GROUPPOLICY_START";
export const FETCH_GROUPPOLICY_SUCCESS = "FETCH_GROUPPOLICY_SUCCESS";
export const FETCH_GROUPPOLICY_FAIL = "FETCH_GROUPPOLICY_FAIL";

//  contract

export const FETCH_CONTRACT_START = "FETCH_CONTRACT_START";
export const FETCH_CONTRACT_SUCCESS = "FETCH_CONTRACT_SUCCESS";
export const FETCH_CONTRACT_FAIL = "FETCH_CONTRACT_FAIL";

//  sub contract

export const FETCH_SUBCONTRACT_START = "FETCH_SUBCONTRACT_START";
export const FETCH_SUBCONTRACT_SUCCESS = "FETCH_SUBCONTRACT_SUCCESS";
export const FETCH_SUBCONTRACT_FAIL = "FETCH_SUBCONTRACT_FAIL";

// Document Type
export const FETCH_DOCUMENTTYPE_START = "FETCH_DOCUMENTTYPE_START";
export const FETCH_DOCUMENTTYPE_SUCCESS = "FETCH_DOCUMENTTYPE_SUCCESS";
export const FETCH_DOCUMENTTYPE_FAIL = "FETCH_DOCUMENTTYPE_FAIL";

// Commerical Structure
export const FETCH_COMMERCIALSTRUCTURE_START =
  "FETCH_COMMERCIALSTRUCTURE_START";
export const FETCH_COMMERCIALSTRUCTURE_SUCCESS =
  "FETCH_COMMERCIALSTRUCTURE_SUCCESS";
export const FETCH_COMMERCIALSTRUCTURE_FAIL = "FETCH_COMMERCIALSTRUCTURE_FAIL";

//Agent

//Sub Agent

export const FETCH_AGENT_START = "FETCH_AGENT_START";
export const FETCH_AGENT_SUCCESS = "FETCH_AGENT_SUCCESS";
export const FETCH_AGENT_FAIL = "FETCH_AGENT_FAIL";

//Sub Agent

export const FETCH_SUBAGENT_START = "FETCH_SUBAGENT_START";
export const FETCH_SUBAGENT_SUCCESS = "FETCH_SUBAGENT_SUCCESS";
export const FETCH_SUBAGENT_FAIL = "FETCH_SUBAGENT_FAIL";

//payment terms
export const FETCH_PAYMENTTERMS_START = "FETCH_PAYMENTTERMS_START";
export const FETCH_PAYMENTTERMS_SUCCESS = "FETCH_PAYMENTTERMS_SUCCESS";
export const FETCH_PAYMENTTERMS_FAIL = "FETCH_PAYMENTTERMS_FAIL";

// Motor Product Tier

export const FETCH_MOTORPRODUCTTIER_START = "FETCH_MOTORPRODUCTTIER_START";
export const FETCH_MOTORPRODUCTTIER_SUCCESS = "FETCH_MOTORPRODUCTTIER_SUCCESS";
export const FETCH_MOTORPRODUCTTIER_FAIL = "FETCH_MOTORPRODUCTTIER_FAIL";

//effectivity-date List
export const FETCH_EFFECTIVITYDATE_START = "FETCH_EFFECTIVITYDATE_START";
export const FETCH_EFFECTIVITYDATE_SUCCESS = "FETCH_EFFECTIVITYDATE_SUCCESS";
export const FETCH_EFFECTIVITYDATE_FAIL = "FETCH_EFFECTIVITYDATE_FAIL";


//Accessory List
export const FETCH_ACCESSORYLIST_START = "FETCH_ACCESSORYLIST_START";
export const FETCH_ACCESSORYLIST_SUCCESS = "FETCH_ACCESSORYLIST_SUCCESS";
export const FETCH_ACCESSORYLIST_FAIL = "FETCH_ACCESSORYLIST_FAIL";

// GenerateCoverages
export const FETCH_GENERATECOVERAGES_START = "FETCH_GENERATECOVERAGES_START";
export const FETCH_GENERATECOVERAGES_SUCCESS =
  "FETCH_GENERATECOVERAGES_SUCCESS";
export const FETCH_GENERATECOVERAGES_FAIL = "FETCH_GENERATECOVERAGES_FAIL";

// Mortgage all

export const FETCH_MORTGAGE_START = "FETCH_MORTGAGE_START";
export const FETCH_MORTGAGE_SUCCESS = "FETCH_MORTGAGE_SUCCESS";
export const FETCH_MORTGAGE_FAIL = "FETCH_MORTGAGE_FAIL";

// Mortgage clause

export const FETCH_MORTGAGECLAUSE_START = "FETCH_MORTGAGECLAUSE_START";
export const FETCH_MORTGAGECLAUSE_SUCCESS = "FETCH_MORTGAGECLAUSE_SUCCESS";
export const FETCH_MORTGAGECLAUSE_FAIL = "FETCH_MORTGAGECLAUSE_FAIL";

// Motor Quatation post
export const CREATE_MOTOR_QUOTATION_START = "CREATE_MOTOR_QUOTATION_START";
export const CREATE_MOTOR_QUOTATION_SUCCESS = "CREATE_MOTOR_QUOTATION_SUCCESS";
export const CREATE_MOTOR_QUOTATION_FAIL = "CREATE_MOTOR_QUOTATION_FAIL";

// motor storage
export const MOTOR_FORM_STORE = "MOTOR_FORM_STORE";
export const MOTOR_FORMAL_FORM_STORE = "MOTOR_FORMAL_FORM_STORE";
export const MOTOR_POLICY_FORM_STORE = "MOTOR_POLICY_FORM_STORE";
// RESET TRAVEL
export const SET_TRAVEL_RESET_QUOTATION = "SET_TRAVEL_RESET_QUOTATION";

// Ctpl Policy

export const CREATE_CTPLPOLICY_START = "CREATE_CTPLPOLICY_START";
export const CREATE_CTPLPOLICY_SUCCESS = "CREATE_CTPLPOLICY_SUCCESS";
export const CREATE_CTPLPOLICY_FAIL = "CREATE_CTPLPOLICY_FAIL";
export const CREATE_CTPLPOLICY_FORM = "CREATE_CTPLPOLICY_FORM";

// Login Shiva
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const MULTI_CHANNEL = "MULTI_CHANNEL";

// customer login shubham
export const CUSTOMER_LOGIN_START = "CUSTOMER_LOGIN_START";
export const CUSTOMER_LOGIN_SUCCESS = "CUSTOMER_LOGIN_SUCCESS";
export const CUSTOMER_LOGIN_FAIL = "CUSTOMER_LOGIN_FAIL";
export const CUSTOMER_AUTH_LOGOUT_START = "CUSTOMER_AUTH_LOGOUT_START";
export const CUSTOMER_AUTH_LOGOUT_SUCCESS = "CUSTOMER_AUTH_LOGOUT_SUCCESS";

export const AUTH_LOGOUT_START = "AUTH_LOGOUT_START";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";

export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAIL = "FETCH_USER_DETAILS_FAIL";

export const FETCH_HIERARCHY_START = "FETCH_HIERARCHY_START";
export const FETCH_HIERARCHY_SUCCESS = "FETCH_HIERARCHY_SUCCESS";
export const FETCH_HIERARCHY_FAIL = "FETCH_HIERARCHY_FAIL";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const HOME_START = "HOME_START";
export const HOME_SUCCESS = "HOME_SUCCESS";
export const HOME_FAIL = "HOME_FAIL";
export const USER_TREE = "USER_TREE";
export const BUSINESS_CARD = "BUSINESS_CARD";

export const ACTIVITIES_START = "ACTIVITIES_START";
export const ACTIVITIES_SUCCESS = "ACTIVITIES_SUCCESS";
export const ACTIVITIES_FAIL = "ACTIVITIES_FAIL";

export const TODO_START = "TODO_START";
export const TODO_SUCCESS = "TODO_SUCCESS";

//KPI DASHBOARD
export const KPI_DASHBOARD_START = "KPI_DASHBOARD_START";
export const KPI_DASHBOARD_SUCCESS = "KPI_DASHBOARD_SUCCESS";
export const KPI_DASHBOARD_FAIL = "KPI_DASHBOARD_FAIL";

//all renewals
export const FETCH_ALL_RENEWALS_START = "FETCH_ALL_RENEWALS_START";
export const FETCH_ALL_RENEWALS_SUCCESS = "FETCH_ALL_RENEWALS_SUCCESS";
export const FETCH_ALL_RENEWALS_FAIL = "FETCH_ALL_RENEWALS_FAIL";
//paid renewals
export const FETCH_PAID_RENEWALS_START = "FETCH_PAID_RENEWALS_START";
export const FETCH_PAID_RENEWALS_SUCCESS = "FETCH_PAID_RENEWALS_SUCCESS";
export const FETCH_PAID_RENEWALS_FAIL = "FETCH_PAID_RENEWALS_FAIL";
//unpaid renewals
export const FETCH_UNPAID_RENEWALS_START = "FETCH_UNPAID_RENEWALS_START";
export const FETCH_UNPAID_RENEWALS_SUCCESS = "FETCH_UNPAID_RENEWALS_SUCCESS";
export const FETCH_UNPAID_RENEWALS_FAIL = "FETCH_UNPAID_RENEWALS_FAIL";
//lapsed renewals
export const FETCH_LAPSED_RENEWALS_START = "FETCH_LAPSED_RENEWALS_START";
export const FETCH_LAPSED_RENEWALS_SUCCESS = "FETCH_LAPSED_RENEWALS_SUCCESS";
export const FETCH_LAPSED_RENEWALS_FAIL = "FETCH_LAPSED_RENEWALS_FAIL";

// renewal details
export const FETCH_DETAILS_RENEWALS_START = "FETCH_DETAILS_RENEWALS_START";
export const FETCH_DETAILS_RENEWALS_SUCCESS = "FETCH_DETAILS_RENEWALS_SUCCESS";
export const FETCH_DETAILS_RENEWALS_FAIL = "FETCH_DETAILS_RENEWALS_FAIL";

// Agent Mirco Site
export const FETCH_AGENT_DETAILS_START = "FETCH_AGENT_DETAILS_START";
export const FETCH_AGENT_DETAILS_SUCCESS = "FETCH_AGENT_DETAILS_SUCCESS";
export const FETCH_AGENT_DETAILS_FAIL = "FETCH_AGENT_DETAILS_FAIL";

// Agent Micro site blog
export const FETCH_BLOGS_START = "FETCH_BLOGS_START";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_BLOGS_FAIL = "FETCH_BLOGS_FAIL";

//fetch company details
export const FETCH_COMPANY_START = "FETCH_COMPANY_START";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAIL = "FETCH_COMPANY_FAIL";

// Advisor List
export const FETCH_ADVISOR_LIST_START = "FETCH_ADVISOR_LIST_START";
export const FETCH_ADVISOR_LIST_SUCCESS = "FETCH_ADVISOR_LIST_SUCCESS";
export const FETCH_ADVISOR_LIST_FAIL = "FETCH_ADVISOR_LIST_FAIL";

// Quatation post
export const CREATE_CTPL_QUOTATION_START = "CREATE_CTPL_QUOTATION_START";
export const CREATE_CTPL_QUOTATION_SUCCESS = "CREATE_CTPL_QUOTATION_SUCCESS";
export const CREATE_CTPL_QUOTATION_FAIL = "CREATE_CTPL_QUOTATION_FAIL";
export const CREATE_CTPL_STOREFORMQUOTATION_SUCCESS =
  "CREATE_CTPL_STOREFORMQUOTATION_SUCCESS";

// QuotationsPolicies application bucket momnet
export const FETCH_ALL_QUOTATIONSPOLICIES_START =
  "FETCH_ALL_QUOTATIONSPOLICIES_START";
export const FETCH_ALL_QUOTATIONSPOLICIES_SUCCESS =
  "FETCH_ALL_QUOTATIONSPOLICIES_SUCCESS";
export const FETCH_ALL_QUOTATIONSPOLICIES_FAIL =
  "FETCH_ALL_QUOTATIONSPOLICIES_FAIL";
// this for the customer
export const FETCH_ALL_QUOTATIONSPOLICIES_CUSTOMER_START =
  "FETCH_ALL_QUOTATIONSPOLICIES_CUSTOMER_START";
export const FETCH_ALL_QUOTATIONSPOLICIES_CUSTOMER_SUCCESS =
  "FETCH_ALL_QUOTATIONSPOLICIES_CUSTOMER_SUCCESS";
export const FETCH_ALL_QUOTATIONSPOLICIES_CUSTOMER_FAIL =
  "FETCH_ALL_QUOTATIONSPOLICIES_CUSTOMER_FAIL";
export const FETCH_ALL_APPLICATIONQUOTATION_CUSTOMER_FAIL =
  "FETCH_ALL_APPLICATIONQUOTATION_CUSTOMER_FAIL";
export const FETCH_ALL_APPLICATIONQUOTATION_CUSTOMER_START =
  "FETCH_ALL_APPLICATIONQUOTATION_CUSTOMER_START";
export const FETCH_ALL_APPLICATIONQUOTATION_CUSTOMER_SUCCESS =
  "FETCH_ALL_APPLICATIONQUOTATION_CUSTOMER_SUCCESS";

// get all application quation data
export const CURRENT_UPDATING_FOR_QUOTATION_ID =
  "CURRENT_UPDATING_FOR_QUOTATION_ID";
export const FETCH_ALL_APPLICATIONQUOTATION_START =
  "FETCH_ALL_APPLICATIONQUOTATION_START";
export const FETCH_ALL_APPLICATIONQUOTATION_SUCCESS =
  "FETCH_ALL_APPLICATIONQUOTATION_SUCCESS";
export const FETCH_ALL_APPLICATIONQUOTATION_FAIL =
  "FETCH_ALL_APPLICATIONQUOTATION_FAIL";
export const CURRENT_DOCUMENTS_CUSTOMER_ID =
  "CURRENT_DOCUMENTS_CUSTOMER_ID";
export const CURRENT_UPDATING_CUSTOMER_TAB =
  "CURRENT_UPDATING_CUSTOMER_TAB";
export const CURRENT_UPDATING_FOR_QUOTATION_CUSTOMER_ID =
  "CURRENT_UPDATING_FOR_QUOTATION_CUSTOMER_ID";
export const FETCH_ALL_TEAM_CUSTOMER_COUNT =
  "FETCH_ALL_TEAM_CUSTOMER_COUNT";
export const FETCH_ALL_TEAM_CUSTOMER_COUNTT =
  "FETCH_ALL_TEAM_CUSTOMER_COUNTT";
export const FETCH_DESIGNATION_CUSTOMER_FAIL =
  "FETCH_DESIGNATION_CUSTOMER_FAIL";
export const FETCH_DESIGNATION_CUSTOMER_START =
  "FETCH_DESIGNATION_CUSTOMER_START";
export const FETCH_DESIGNATION_CUSTOMER_SUCCESS =
  "FETCH_DESIGNATION_CUSTOMER_SUCCESS";
export const HIDE_CUSTOMER_LOADER =
  "HIDE_CUSTOMER_LOADER";
export const SHOW_CUSTOMER_LOADER =
  "SHOW_CUSTOMER_LOADER";
export const UPDATE_ALLCATED_CHECKED_CUSTOMER_LEADS =
  "UPDATE_ALLCATED_CHECKED_CUSTOMER_LEADS";
export const UPDATE_ALLCATION_TAB_CUSTOMER_POSSITION =
  "UPDATE_ALLCATION_TAB_CUSTOMER_POSSITION";
export const UPDATE_TAB_CUSTOMER_POSSITION =
  "UPDATE_TAB_CUSTOMER_POSSITION";

// customer listing
export const CURRENT_UPDATING_ID_CUSTOMER = "CURRENT_UPDATING_ID_CUSTOMER";
export const FETCH_ALL_GETCUSTOMERLISTING_START =
  "FETCH_ALL_GETCUSTOMERLISTING_START";
export const FETCH_ALL_GETCUSTOMERLISTING_SUCCESS =
  "FETCH_ALL_GETCUSTOMERLISTING_SUCCESS";
export const FETCH_ALL_GETCUSTOMERLISTING_FAIL =
  "FETCH_ALL_GETCUSTOMERLISTING_FAIL";

// GET QOUTATION
export const FETCH_ALL_GETQUOTATION_START = "FETCH_ALL_GETQUOTATION_START";
export const FETCH_ALL_GETQUOTATION_SUCCESS = "FETCH_ALL_GETQUOTATION_SUCCESS";
export const FETCH_ALL_GETQUOTATION_FAIL = "FETCH_ALL_GETQUOTATION_FAIL";

// GET Policies
export const FETCH_ALL_GETPOLICIES_START = "FETCH_ALL_GETPOLICIES_START";
export const FETCH_ALL_GETPOLICIES_SUCCESS = "FETCH_ALL_GETPOLICIES_SUCCESS";
export const FETCH_ALL_GETPOLICIES_FAIL = "FETCH_ALL_GETPOLICIES_FAIL";

export const SET_TRIP_INFO = "SET_TRIP_INFO";
export const SET_TRAVEL_USER_DETAILS = "SET_TRAVEL_USER_DETAILS";
export const SET_TRAVEL_POLICY_DETAILS = "SET_TRAVEL_POLICY_DETAILS";
export const SET_TRAVEL_QUOTATION_DETAILS = "SET_TRAVEL_QUOTATION_DETAILS";
export const SET_TRAVEL_CUSTOMER_INFO = "SET_TRAVEL_CUSTOMER_INFO";
export const SET_TRAVELER_INFO = "SET_TRAVELER_INFO";
export const SET_TRAVEL_POLICY_QUOTATION = "SET_TRAVEL_POLICY_QUOTATION";

// store checkbox state
export const SET_CHECKBOX_INFO = "SET_CHECKBOX_INFO";

// reset form data
export const RESET_FORM_DATA = "RESET_FORM_DATA";

export const VEHICAL_PRICE_HANDLER = "VEHICAL_PRICE_HANDLER";

// upload documents

export const ADD_VEHICAL_CERTIFICATE = "ADD_VEHICAL_CERTIFICATE";
export const ADD_PHOTO_ID = "ADD_PHOTO_ID";
export const ADD_SIGN = "ADD_SIGN";

export const SET_MOTOR_CONDITIONAL_RENDER = "SET_MOTOR_CONDITIONAL_RENDER";
export const RESET_MOTOR_FORM_DATA = "RESET_MOTOR_FORM_DATA";

// hyperverge
export const HYPERVERGE_CTPL_DATA = "HYPERVERGE_CTPL_DATA";
export const HYPERVERGE_TRAVEL_DATA = "HYPERVERGE_TRAVEL_DATA";
export const HYPERVERGE_MOTOR_DATA = "HYPERVERGE_MOTOR_DATA";
export const CURRENT_LOB_DATA = "CURRENT_LOB_DATA";

// SAVE QUOTATION PDF
export const FETCH_SAVEQUOTATIONPDF_START = "FETCH_SAVEQUOTATIONPDF_START";
export const FETCH_SAVEQUOTATIONPDF_SUCCESS = "FETCH_SAVEQUOTATIONPDF_SUCCESS";
export const FETCH_SAVEQUOTATIONPDF_FAIL = "FETCH_SAVEQUOTATIONPDF_FAIL";
//  CHECK PWA OFFLINE
export const OFFLINE_STORE_FLAG = "OFFLINE_STORE_FLAG";

// cipher text
export const FETCH_SAVE_STORE_CIPHER_TEXT_START = "FETCH_SAVE_STORE_CIPHER_TEXT_START";
export const FETCH_SAVE_STORE_CIPHER_TEXT_SUCCESS = "FETCH_SAVE_STORE_CIPHER_TEXT_SUCCESS";
export const FETCH_SAVE_STORE_CIPHER_TEXT_FAIL = "FETCH_SAVE_STORE_CIPHER_TEXT_FAIL";

// CUSTOMER TOKEN STORE
export const CUSTOMER_TOKEN = "CUSTOMER_TOKEN";
export const JWT_STORE_RESPONSE = "JWT_STORE_RESPONSE"

// JWT STORE CUSTOMER text
export const FETCH_CUSTOMER_JWT_STORE_START = "FETCH_CUSTOMER_JWT_STORE_START";
export const FETCH_CUSTOMER_JWT_STORE_SUCCESS = "FETCH_CUSTOMER_JWT_STORE_SUCCESS";
export const FETCH_CUSTOMER_JWT_STORE_FAIL = "FETCH_CUSTOMER_JWT_STORE_FAIL";

// GET COMMERCIAL_VALUE
export const COMMERCIAL_VALUE = "COMMERCIAL_VALUE"
// GET  CUSTOMER_MOBILE
export const CUSTOMER_MOBILE = "CUSTOMER_MOBILE"

// agent on boarding store
export const CREATE_AGENTONBOARD_STORE_SUCCESS = "CREATE_AGENTONBOARD_STORE_SUCCESS";
export const RESET_AGENT_ON_BOARD_FORM_DATA = "RESET_AGENT_ON_BOARD_FORM_DATA";
export const RESET_LICENSED_AFFILIATES_DATA = "RESET_LICENSED_AFFILIATES_DATA"
export const CREATE_AGENTONBOARD_LISENCEFORM_STORE_SUCCESS = "CREATE_AGENTONBOARD_LISENCEFORM_STORE_SUCCESS";
export const CREATE_AGENTONALLAPLICATIONFORM_STORE_SUCCESS = "CREATE_AGENTONALLAPLICATIONFORM_STORE_SUCCESS";
export const GET_ALLAGENTFORM_STORE = "GET_ALLAGENTFORM_STORE"

// AGENT CYPHER STORE
export const AGENT_CYPHER_STORE = "AGENT_CYPHER_STORE";
export const AGENT_REGISTER_KEY_STORE = "AGENT_REGISTER_KEY_STORE";

// SUB AGENT STORE DATA 
export const SUB_AGENT_STORE = "SUB_AGENT_STORE";

// fitler data stored
export const FILTER_QUATAION_STORE = "FILTER_QUATAION_STORE";
export const FILTER_POLICIES_STORE = "FILTER_POLICIES_STORE";

// all premium dashboard
export const ALL_DASHBOARD_STORE = "ALL_DASHBOARD_STORE";
// applied filter 
export const UPDATE_FILTER_TAB_POSSITION = "UPDATE_FILTER_TAB_POSSITION";
// bulk upload pagination 
export const BULK_UPLOAD_PAGINATION_STORE = "BULK_UPLOAD_PAGINATION_STORE"

// login mobile number 
export const CUSTOMER_LOGIN_MOBILE_TOKEN = "CUSTOMER_LOGIN_MOBILE_TOKEN";
export const LI_AFFI_TEXT = "LI_AFFI_TEXT";

// userinfo token store 
export const LOGIN_SUCCESS_USERINFO = "LOGIN_SUCCESS_USERINFO";