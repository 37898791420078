import * as actionTypes from "../actions/actionTypes";

export const GetAgentCypherStore = (payload) => {
    return {
        type: actionTypes.AGENT_CYPHER_STORE,
        AgentCypherStore: payload,
    };
};

export const GetAgentRegisterKeyStore = (payload) => {
    return {
        type: actionTypes.AGENT_REGISTER_KEY_STORE,
        registerKeyStore: payload,
    };
};
