
const NODE_ENV = process.env.REACT_APP_APPLICATION_TYPE
const DEV_BASE_URL = process.env.REACT_APP_NODE_URL
const OrigineRoute = window.location.origin;

export default {
  baseURL:DEV_BASE_URL,
  auth: 'auth/',
  secure: 'secure/',
  NODE_ENV: NODE_ENV,
  ProjectLink:OrigineRoute
};