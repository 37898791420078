import React from "react";
import "./AllFormFooter.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const AllFormFooter = () => {
  const vehicalPriceInfo = useSelector((state)=> state?.vehicalePriceHandler?.formData)
  const location = useLocation();

   let vehicleCategoryApi = useSelector((state) => state?.make?.vehiclecategory);

  let vehiclePrice = "";
  let vehicleName = "";
  if (vehicleCategoryApi !== undefined && Object.keys(vehicleCategoryApi).length !== 0){
    vehicleCategoryApi.map((item) => {
      if (vehicalPriceInfo === item.value) {
        vehiclePrice = item.price;
        vehicleName = item.name;
      }
    });
  }

    let currentURL = window.location.href;
    const path = new URL(currentURL).pathname;


  return (
    <>
      <div className="bac">
        <div className="allformfooter">
          <div className="footer-details">
            <div className="firstdata">
              <p>YOUR SELECTION</p>
              <h2>
                Policy for Individual {path === "/commercial-structure" ? 'CI' : 'CTPL'}
              </h2>
            </div>
          </div>
        </div>  
      </div>
     
    </>
  );
};

export default AllFormFooter;
