import * as actionTypes from "../actions/actionTypes";

const initialState = {
  pwa: true,
};

const pwaOfflineFlag = (state, action) => {
  // Your logic to update state based on action
  return {
    ...state,
    pwa: action.formData.name,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // lead
    case actionTypes.OFFLINE_STORE_FLAG:
      return pwaOfflineFlag(state, action);

    default:
      return state;
  }
};

export default reducer;
