import * as actionTypes from "./actionTypes";
import axios from "../../axios-common";
import axiosRequest from "../../axios-request/request.methods";
import { convertToIndianTimezone } from "../../helper/utils";

export const pwaOfflineFlag = (formData) => {
  return {
    type: actionTypes.OFFLINE_STORE_FLAG,
    formData: formData,
  };
};
