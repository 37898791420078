import Keycloak from "keycloak-js";
import { stoageSetter } from '../helpers';

let initOptions = {
    url: process.env.REACT_APP_KEY_CLOCK_REDIRECTION,
    realm: process.env.REACT_APP_KEY_REALM,
    clientId: process.env.REACT_APP_KEY_CLIENT_ID,
}
    // let currentURL = window.location.href;
    // console.log('currentURL',currentURL)
// let initOptions = {
//     url: "https://auth.uat.oona-insurance.com/",
//     realm: "ph_rlm_external",
//     clientId: "ph_cl_kahoona_app",
// }

const keycloak = new Keycloak(initOptions);

// keycloak.redirectUri= currentURL;

export default keycloak;