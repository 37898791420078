import * as actionTypes from "../actions/actionTypes";

export const GetCustomerToken = (payload) => {
    return {
        type: actionTypes.CUSTOMER_TOKEN,
        CustomerToken: payload,
    };
};

export const GetStoreAllJWTResponse = (payload) => {
    return {
        type: actionTypes.JWT_STORE_RESPONSE,
        jwtLogin: payload,
    };
};
