import axios from 'axios'; 
export const baseURL = '';

let defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json' ,
    'sid':'',
    'token':'',
    'Authorization':''
}
const instance = axios.create({
    baseURL: baseURL,
    headers: defaultHeaders
});

export default instance;