import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    Sub_agent: [],
}

const GetSubAgentStore = (state, action) => {
    return updateObject(state, { Sub_agent: action?.SubAgnetDataStore });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        // leadtchh
            case actionTypes.SUB_AGENT_STORE:
            return GetSubAgentStore(state, action);
        default:
            return state;
    }
};

export default reducer;