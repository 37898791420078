import axios from 'axios'; 
export const baseURL =process.env.REACT_APP_NODE_URL+'secure/';
export const baseURLAgentMicroSite = '';
let defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

const instance = axios.create({
    baseURL: baseURL,
    baseURLAgentMicroSite:baseURLAgentMicroSite,
    headers: defaultHeaders
});

export default instance;