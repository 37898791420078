import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    Customer_Token: "",
    allJWT_login_store: ""
}

const GetCustomerToken = (state, action) => {
    return updateObject(state, { Customer_Token: action?.CustomerToken });
};

const GetStoreAllJWTResponse = (state, action) => {
    return updateObject(state, { allJWT_login_store: action?.jwtLogin });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // leadtchh
        case actionTypes.CUSTOMER_TOKEN:
            return GetCustomerToken(state, action);
            case actionTypes.JWT_STORE_RESPONSE:
            return GetStoreAllJWTResponse(state, action);
        default:
            return state;
    }
};

export default reducer;