import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    current_pagination: "",
}

const GetBulkUploadPaginationStore = (state, action) => {
    return updateObject(state, { current_pagination: action?.BulkUploadPaginationStore });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // leadtchh
        case actionTypes.BULK_UPLOAD_PAGINATION_STORE:
            return GetBulkUploadPaginationStore(state, action);
        default:
            return state;
    }
};

export default reducer;